/* 
This file contains the CSS for the entire application.

The CSS is organized into sections based on the components they style.
Each section is separated by a comment block that describes the styles within that section.
*/

@import url("https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Roboto:wght@400&family=Rubik:wght@500&display=swap");

/* 
  <color name="Brunswick green" hex="034c3c" r="3" g="76" b="60" />
  <color name="Cream" hex="fffdd0" r="255" g="253" b="208" />
  <color name="Celestial Blue" hex="279af1" r="39" g="154" b="241" />
  <color name="Bittersweet" hex="ff6f61" r="255" g="111" b="97" />
  <color name="Battleship gray" hex="8a8d8f" r="138" g="141" b="143" />
*/

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #fffde6;
  scroll-behavior: smooth;
}

body {
  font-family: "Lora", serif;
  background-color: #fffde6;
  color: #0b4638;
  padding-top: 6em;
}

/* Responsive Header */
@media (max-width: 768px) {
  .header {
    padding: 0.5em 1.5em;
    font-size: 1em;
  }

  .logo h1 {
    font-size: 1.2em;
  }

  .header .logo-image {
    height: 40px;
    margin-right: 0.5em;
  }

  .nav-links {
    display: none;
  }

}

/* About Section Responsive Layout */
@media (max-width: 768px) {
  .about-description-box {
    display: none;
  }

  .about-flex-container {
    flex-direction: column !important;
  }

  .about-description-text {
    display: block !important; /* Show the text on small screens */
    font-family: "Roboto", sans-serif;
  color: #034c3c;
  text-align: left;
  padding: 1em;
  }
}

/* Hide the text on larger screens */
.about-description-text {
  display: none;
}

/* Flex Container for Graph and Description, mostly just horizontal stuff */
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-box {
  width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding-left: 1em; 
  margin-bottom: -2em;
}

@media (max-width: 600px) {
  .chart-box {
    width: 100%; 
    min-width: 350px; 
    margin-left: -4.5em;
      margin-bottom: -15em;
  }
}

/* Nav Bar styles */
.logo h1 {
  font-size: 1.5em;
  font-family: "Rubik", sans-serif;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin: 0 1em;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
}

/* Section Styles */
.section {
  padding: 5em 3em;
  text-align: center;
}

.section:nth-of-type(odd) {
  background-color: #fffde6;
}

.section:nth-of-type(even) {
  background-color: #f2e9db;
}

.section h2 {
  font-size: 2em;
  margin-bottom: 1em;
}

.section .description {
  background-color: #fffdd0;
  padding: 1em;
  border-radius: 8px;
  margin-bottom: 2em;
}

.section .description .italic {
  font-style: italic;
}

/* About Styles */
.about {
  padding: 4em 3em;
  color: rgb(3, 0, 0);
  text-align: center;
  font-size: 1.5em;
  position: relative;
}

.about-description-box {
  background-color: #cdd9b2;
  padding: 2em;
  border-radius: 12px;
  margin: 0 auto;
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 1em;
  margin-top: 1em;
  color: #034c3c;
  height: auto;
}

.about-flex-container {
  display: flex;
  flex-direction: row; 
  justify-content: center; 
  align-items: center; 
  padding: 1em 0;
}

.profile-image {
 width: auto;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

.about-description-box {
  max-width: 600px;
  background-color: #cdd9b2;
  border-radius: 12px;
  font-family: "Roboto", sans-serif;
  color: #034c3c;
  text-align: left;
  margin-right: 5em;
}

/* Header Styles */
.header {
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 3em;
  background-color: rgba(
    11,
    70,
    56,
    0.9
  ); /* Semi-transparent background for blur effect */
  backdrop-filter: blur(10px); 
  color: white;
  position: fixed;
  width: 85%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.header:hover {
  background-color: rgba(
    9,
    76,
    50,
    0.95
  ); 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Logo Styling */
.logo h1 {
  font-size: 1.5em;
  margin: 0;
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo-image {
  margin-right: 10px;
  height: 60px;
    padding-right: 1em;
}

/* Navigation Links */
.nav-links a {
  color: white;
  text-decoration: none;
  margin: 0 1em;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #279af1;
}

/* Button Container */
.buttons-container {
  display: flex;
  justify-content: center;
  gap: 2em;
}

/* Button Styling */
.button {
  background-color: #034c3c;
  padding: 1em 2em;
  border-radius: 12px;
  display: flex;
  position: relative;
  width: 200px;
  height: 100px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
}

.button:hover {
  background-color: rgb(5, 41, 27); /* Darken the background on hover */
  transform: scale(1.05); /* Scale up the button on hover */
}

/* Button Icon */
.button-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px; 
}

.button-label {
  color: white;
  font-weight: bold;
  font-size: 2em;
}

/* Text Align Top Left */
.button-label {
  position: absolute;
  color: white;
  font-weight: bold;
  top: 10px;
  left: 10px;
  font-size: 2em;
}

/* Icon Align Bottom Right */
.button-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 48px; 
  height: 48px;
}

@media (max-width: 600px) {
  .button {
    min-width: 150px; 
  }
}

/* Italicize Specific Word in Section Title */
.section h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
  text-align: center;
}

.description-text {
  font-family: "Roboto", sans-serif;
  font-size: 1.2em;
  width: 60%;
  margin: 0 auto; 
}

/* Footer Styles */
.footer {
  padding: 4em 3em;
  background-color: #034c3c;
  color: white;
  text-align: center;
  font-size: 0.9em;
  font-family: Roboto, sans-serif;
}

.footer h3 {
  font-size: 1.5em;
  margin-bottom: 1em;
  font-family: "Lora", serif;
  color: #ffffff;
}

.footer .contact-info {
  margin-top: 1em;
  font-family: Roboto, sans-serif;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer span {
  display: block;
  margin-top: 0.5em;
}

.legal-info {
  margin-top: 2em;
  font-size: 0.8em;
}

.legal-info a {
  color: white;
  margin: 0 5px;
}

.legal-info a:hover {
  text-decoration: underline;
}

/* Flex Container for KPIs and Chart */
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2em;
  margin-right: 3em;
  position: relative;
}
